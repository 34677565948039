import React from "react"
import Layout from "../components/_layout"
import Article, { articleShape } from "../components/Article"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const ArticlePage = ({ data: { article } }) => (
  <Layout lang={article.node_locale}>
    <div className="section-dark">
      <Article article={article} />
    </div>
  </Layout>
)

ArticlePage.propTypes = {
  data: PropTypes.shape({
    article: articleShape,
  }),
}

export default ArticlePage

export const query = graphql`
  query($id: String!, $locale: String!) {
    article: contentfulArticle(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      ...article
    }
  }
`
